/**
 * constants
 */
import { AMOUNT_PRECISION } from '../constants/billing';
import axios from 'axios';
import qs from './querystring';

/**
 * debounce expensive function to not call it on each multiple consecutive calls
 * and wait timeout before call
 *
 * example: search-request to db on users input
 *
 * @param func {Function} function to debounce
 * @param timeout {Number} ms timeout of debounce
 * @return {Function}
 */

export function debounce(func, timeout) {
	let timer = null;
	return function(...args) {
		const onComplete = () => {
			func.apply(this, args);
			timer = null;
		};
		if (timer) clearTimeout(timer);
		timer = setTimeout(onComplete, timeout);
	};
}

/**
 * @param date {Number|Date|String}
 * @return {string}
 */
export function formatDate(date) {
	let d = new Date(date);
	if (isNaN(d.getTime())) d = new Date();
	return [d.getFullYear(), d.getMonth() + 1, d.getDate()]
		.map(item => item.toString().padStart(2, '0'))
		.join('-');
}

/**
 * @param date {Number|Date|String}
 * @return {string}
 */
export function formatDateTime(date) {
	let d = new Date(date);
	if (isNaN(d.getTime())) d = new Date();
	let [Y, M, D, h, m, s] = [
		d.getFullYear(),
		d.getMonth() + 1,
		d.getDate(),
		d.getHours(),
		d.getMinutes(),
		d.getSeconds(),
	].map(item => item.toString().padStart(2, '0'));
	return `${Y}-${M}-${D}, ${h}:${m}:${s}`;
}

/**
 * @param number {Number}
 * @param precision {Number}
 * @return {String}
 */
export function formatWithPrecision(number, precision) {
	if (isNaN(number)) {
		number = 0;
	}
	return isFinite(number) ? number.toFixed(precision) : number.toString();
}

/**
 * @param amt {String|Number}
 * @return {string}
 */
export function formatAmount(amt) {
	return formatWithPrecision(Number(amt), AMOUNT_PRECISION);
}

/**
 * @param amount {Number}
 * @param leads {Number}
 * @return {string}
 */
export function formatCPL(amount, leads) {
	return formatWithPrecision(amount / leads, AMOUNT_PRECISION);
}

export async function copyTextToClipboard(text) {
	if (navigator.clipboard) {
		await navigator.clipboard.writeText(text);
		return;
	}

	const textArea = document.createElement('textarea');
	textArea.value = text;

	// Avoid scrolling to bottom
	textArea.style.top = '0';
	textArea.style.left = '0';
	textArea.style.position = 'fixed';

	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		document.execCommand('copy');
	} catch (err) {
		console.error(err);
	}

	document.body.removeChild(textArea);
}

export async function getAll(url = '', filters = {}) {
	try {
		let page = 1;
		let total = 0;
		const limit = 10;
		const items = [];
		do {
			const params = {
				limit,
				offset: limit * (page - 1),
				filters,
			};
			const {
				data: { data, status, error },
			} = await axios.get(`${url}?${qs.stringify(params)}`);

			if (status !== true) {
				throw new Error(error);
			}

			Array.prototype.push.apply(items, data.items);

			total = data.total;
		} while (total >= limit * (++page - 1));

		return items;
	} catch (e) {
		throw new Error(e.toString());
	}
}
