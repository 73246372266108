import BaseModel from './base.model';

class SubGroupModel extends BaseModel {
	constructor(data) {
		super();
		data = data || {};

		const now = new Date();

		this.id = data.id || '';
		this.name = data.name || '';
		this.clients = data.clients || [];
		this.startDate =
			(data.start_date && new Date(data.start_date).getTime()) ||
			new Date(
				now.getFullYear(),
				now.getMonth(),
				now.getDate(),
				0,
				0,
				0,
				0,
			).getTime();
		this.group = data.group || null;
	}
}

export default SubGroupModel;
