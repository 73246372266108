import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { API_CPL_LIST, LOCAL_DASHBOARD } from '../../constants/urls';

import '../../dist/css/skins.min.css';
import '../../dist/css/skin-blue.min.css';
import logo from '../../dist/img/logo-pr-crm.svg';
import userLogo from '../../dist/img/user1.jpg';

import { logout } from '../../actions/auth';

/**
 * internal utils
 */
import { formatCPL } from '../../helpers/functions';
import qs from '../../helpers/querystring';

const bodyDOMElement = document.body;

class Header extends React.Component {
	state = {
		leads: 0,
		amount: 0,
		isLoading: true,
	};

	componentDidMount() {
		bodyDOMElement.classList.add(
			`skin-${process.env.REACT_APP_THEME || 'blue'}`,
		);
		bodyDOMElement.classList.add('sidebar-mini');
		this.messagesDOMElement = document.getElementsByClassName(
			'messages-menu',
		)[0];
		this.notificationsDOMElement = document.getElementsByClassName(
			'notifications-menu',
		)[0];
		this.tasksDOMElement = document.getElementsByClassName('tasks-menu')[0];
		this.userDOMElement = document.getElementsByClassName('user-menu')[0];

		this.getData();
	}

	componentWillUnmount() {
		bodyDOMElement.classList.remove('skin-blue');
		bodyDOMElement.classList.remove('sidebar-mini');
	}

	render() {
		const { leads, amount, isLoading } = this.state;
		return (
			<header className="main-header">
				<Link to={LOCAL_DASHBOARD} className="logo">
					<span className="logo-mini">
						<img src={logo} alt="logo" />
					</span>
					<span className="logo-lg">
						<img src={logo} alt="logo" />
					</span>
				</Link>
				<nav className="navbar navbar-static-top">
					<a
						href="#/"
						className="sidebar-toggle"
						onClick={() => {
							this.handleToggleClassName(bodyDOMElement, 'sidebar-open');
							this.handleToggleClassName(bodyDOMElement, 'sidebar-collapse');
						}}
					>
						<span className="sr-only">Toggle navigation</span>
						<span className="icon-bar" />
						<span className="icon-bar" />
						<span className="icon-bar" />
					</a>
					<div className="navbar-custom-menu">
						<ul className="nav navbar-nav">
							{isLoading === false && (
								<>
									<li>
										<a href="#/">Leads: {leads}</a>
									</li>
									<li>
										<a href="#/">Amount: {amount}</a>
									</li>
									<li>
										<a href="#/">CPL: {formatCPL(amount, leads)}</a>
									</li>
								</>
							)}
							<li className="dropdown messages-menu">
								<a
									href="#/"
									className="dropdown-toggle"
									onClick={() =>
										this.handleToggleClassName(this.messagesDOMElement, 'open')
									}
								>
									<i className="fa fa-envelope-o" />
									<span className="label label-success">4</span>
								</a>
								<ul className="dropdown-menu">
									<li className="header">You have 4 messages</li>
									<li>
										<ul className="menu">
											<li>
												<a href="#/">
													<div className="pull-left">
														<img
															src={userLogo}
															className="img-circle"
															alt="User Avatar"
														/>
													</div>
													<h4>
														Support Team
														<small>
															<i className="fa fa-clock-o" /> 5 mins
														</small>
													</h4>
													<p>Why not buy a new awesome theme?</p>
												</a>
											</li>
											<li>
												<a href="#/">
													<div className="pull-left">
														<img
															src={userLogo}
															className="img-circle"
															alt="User Avatar"
														/>
													</div>
													<h4>
														AdminLTE Design Team
														<small>
															<i className="fa fa-clock-o" /> 2 hours
														</small>
													</h4>
													<p>Why not buy a new awesome theme?</p>
												</a>
											</li>
											<li>
												<a href="#/">
													<div className="pull-left">
														<img
															src={userLogo}
															className="img-circle"
															alt="User Avatar"
														/>
													</div>
													<h4>
														Developers
														<small>
															<i className="fa fa-clock-o" /> Today
														</small>
													</h4>
													<p>Why not buy a new awesome theme?</p>
												</a>
											</li>
											<li>
												<a href="#/">
													<div className="pull-left">
														<img
															src={userLogo}
															className="img-circle"
															alt="User Avatar"
														/>
													</div>
													<h4>
														Sales Department
														<small>
															<i className="fa fa-clock-o" /> Yesterday
														</small>
													</h4>
													<p>Why not buy a new awesome theme?</p>
												</a>
											</li>
											<li>
												<a href="#/">
													<div className="pull-left">
														<img
															src={userLogo}
															className="img-circle"
															alt="User Avatar"
														/>
													</div>
													<h4>
														Reviewers
														<small>
															<i className="fa fa-clock-o" /> 2 days
														</small>
													</h4>
													<p>Why not buy a new awesome theme?</p>
												</a>
											</li>
										</ul>
									</li>
									<li className="footer">
										<a href="#/">See All Messages</a>
									</li>
								</ul>
							</li>
							<li className="dropdown notifications-menu">
								<a
									href="#/"
									className="dropdown-toggle"
									onClick={() =>
										this.handleToggleClassName(
											this.notificationsDOMElement,
											'open',
										)
									}
								>
									<i className="fa fa-bell-o" />
									<span className="label label-warning">10</span>
								</a>
								<ul className="dropdown-menu">
									<li className="header">You have 10 notifications</li>
									<li>
										<ul className="menu">
											<li>
												<a href="#/">
													<i className="fa fa-users text-aqua" /> 5 new members
													joined today
												</a>
											</li>
											<li>
												<a href="#/">
													<i className="fa fa-warning text-yellow" /> Very long
													description here that may not fit into the page and
													may cause design problems
												</a>
											</li>
											<li>
												<a href="#/">
													<i className="fa fa-users text-red" /> 5 new members
													joined
												</a>
											</li>

											<li>
												<a href="#/">
													<i className="fa fa-shopping-cart text-green" /> 25
													sales made
												</a>
											</li>
											<li>
												<a href="#/">
													<i className="fa fa-user text-red" /> You changed your
													username
												</a>
											</li>
										</ul>
									</li>
									<li className="footer">
										<a href="#/">View all</a>
									</li>
								</ul>
							</li>
							<li className="dropdown tasks-menu">
								<a
									href="#/"
									className="dropdown-toggle"
									onClick={() =>
										this.handleToggleClassName(this.tasksDOMElement, 'open')
									}
								>
									<i className="fa fa-flag-o" />
									<span className="label label-danger">9</span>
								</a>
								<ul className="dropdown-menu">
									<li className="header">You have 9 tasks</li>
									<li>
										<ul className="menu">
											<li>
												<a href="#/">
													<h3>
														Design some buttons
														<small className="pull-right">20%</small>
													</h3>
													<div className="progress xs">
														<div
															className="progress-bar progress-bar-aqua"
															style={{ width: '20%' }}
															role="progressbar"
															aria-valuenow="20"
															aria-valuemin="0"
															aria-valuemax="100"
														>
															<span className="sr-only">20% Complete</span>
														</div>
													</div>
												</a>
											</li>
											<li>
												<a href="#/">
													<h3>
														Create a nice theme
														<small className="pull-right">40%</small>
													</h3>
													<div className="progress xs">
														<div
															className="progress-bar progress-bar-green"
															style={{ width: '40%' }}
															role="progressbar"
															aria-valuenow="20"
															aria-valuemin="0"
															aria-valuemax="100"
														>
															<span className="sr-only">40% Complete</span>
														</div>
													</div>
												</a>
											</li>
											<li>
												<a href="#/">
													<h3>
														Some task I need to do
														<small className="pull-right">60%</small>
													</h3>
													<div className="progress xs">
														<div
															className="progress-bar progress-bar-red"
															style={{ width: '60%' }}
															role="progressbar"
															aria-valuenow="20"
															aria-valuemin="0"
															aria-valuemax="100"
														>
															<span className="sr-only">60% Complete</span>
														</div>
													</div>
												</a>
											</li>
											<li>
												<a href="#/">
													<h3>
														Make beautiful transitions
														<small className="pull-right">80%</small>
													</h3>
													<div className="progress xs">
														<div
															className="progress-bar progress-bar-yellow"
															style={{ width: '80%' }}
															role="progressbar"
															aria-valuenow="20"
															aria-valuemin="0"
															aria-valuemax="100"
														>
															<span className="sr-only">80% Complete</span>
														</div>
													</div>
												</a>
											</li>
										</ul>
									</li>
									<li className="footer">
										<a href="#/">View all tasks</a>
									</li>
								</ul>
							</li>
							<li className="dropdown user user-menu">
								<a
									href="#/"
									className="dropdown-toggle"
									onClick={() =>
										this.handleToggleClassName(this.userDOMElement, 'open')
									}
								>
								</a>
								<div
									className="dropdown-menu"
									style={{ minWidth: 0, width: 'auto' }}
								>
									<button
										className="btn btn-default btn-flat"
										onClick={this.props.logout}
									>
										Sign out
									</button>
								</div>
							</li>
						</ul>
					</div>
				</nav>
			</header>
		);
	}

	getData = async () => {
		try {
			const now = new Date();
			const {
				data: {
					status,
					data: { items },
				},
			} = await axios.get(
				`${API_CPL_LIST}?${qs.stringify({
					filters: {
						users: [this.props.user.id],
						date: {
							start: new Date(now.getFullYear(), now.getMonth(), 1).getTime(),
							end:
								new Date(now.getFullYear(), now.getMonth() + 1, 1).getTime() -
								1,
						},
					},
					limit: 1,
				})}`,
			);

			if (Boolean(status) === false) {
				throw new Error('CPL API Error');
			}

			this.setState({
				leads: items?.[0]?.leads,
				amount: items?.[0]?.amount,
				isLoading: false,
			});
		} catch (e) {
			console.error(e);
			this.setState({ isLoading: false });
		}
	};

	handleToggleClassName = (element, className) => {
		element.classList.contains(className)
			? element.classList.remove(className)
			: element.classList.add(className);
	};
}

export default connect(
	({ auth: { user, access, role } }) => ({ role, user, access }),
	dispatch => ({
		logout: () => dispatch(logout()),
	}),
)(Header);
