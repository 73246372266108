/**
 * external libs
 */
import axios from 'axios';

/**
 * internal services
 */
import BaseService from './base.service';
import { API_USER_GET, API_USERS_LIST } from '../constants/urls';
import * as ROLES from '../constants/roles';

export default class UserService extends BaseService {
	static list(params) {
		return axios.get(API_USERS_LIST, {
			params,
		});
	}

	static async all(params) {
		const limit = 10;
		let offset = 0;
		let users = [];
		let total = 0;
		do {
			const {
				data: { status, data },
			} = await UserService.list({ limit, offset, ...params });
			if (status === false) {
				throw new Error('UserService.all: Getting users list error');
			}
			offset += limit;
			total = data.total;
			users = [].concat(users, data.items);
		} while (users.length < total);
		return users;
	}

	static async get(id) {
		const {
			data: { status, data, error },
		} = await axios.get(API_USER_GET(id));
		if (status === false) {
			throw error;
		}
		return data;
	}

	static setLeadColumnsOnLocalStorage(role) {
		let leadColumns = localStorage.getItem('leadColumns');

		if (leadColumns !== null && leadColumns !== 'null')
			return JSON.parse(leadColumns);

		leadColumns = this.setLeadColumns(role);

		localStorage.setItem('leadColumns', JSON.stringify(leadColumns));

		return leadColumns;
	}

	static setLeadColumns(role) {
		const funcRule = rule => {
			switch (rule) {
				case 'fullName':
					return {
						firstName: true,
						lastName: true,
					};
				case 'status':
					return {
						status: true,
					};
				case 'ip':
					return { ip: true };
				case 'contacts':
					return {
						email: true,
						phone: true,
					};
				case 'clientStatus':
					return {
						clientStatus: true,
						unifiedStatus: true,
					};
				case 'client':
					return {
						client: true,
						...funcRule('clientStatus'),
					};
				case 'user':
					return {
						user: true,
					};
				case 'id':
					return {
						id: true,
					};
				case 'actions':
					return {
						actions: true,
					};
				case 'country':
					return {
						country: true,
					};
				case 'sentAt':
					return {
						sentAt: true,
					};
				case 'landing':
					return {
						landing: true,
					};
				case 'preLanding':
					return {
						preLanding: true,
					};
				case 'geolocation':
					return {
						geolocation: true,
					};
				default:
					return {
						...funcRule('country'),
						...funcRule('sentAt'),
						...funcRule('preLanding'),
						...funcRule('landing'),
						...funcRule('geolocation'),
						hasAutologin: true,
						isResend: true,
						geoGroup: true,
						demonetization: true,
						nationality: true,
						clickId: true,
						domain: true,
						fbtool: true,
						accountName: true,
						accountType: true,
						cardSystem: true,
						antidectProfile: true,
						kreo: true,
						kreoMain: true,
						application: true,
						sub1: false,
						sub2: false,
						sub3: false,
						sub4: false,
						sub5: false,
						sub6: false,
						sub7: false,
						sub8: false,
						sub9: false,
						sub10: false,
						currency: false,
						payout: false,
					};
			}
		};

		switch (role) {
			case ROLES.AFFILIATE:
				return {
					...funcRule(),
					...funcRule('fullName'),
					...funcRule('id'),
				};
			case ROLES.ANALYTIC:
				return {
					...funcRule(),
					...funcRule('status'),
					...funcRule('id'),
					...funcRule('client'),
				};
			case ROLES.TECH_ADMIN:
				return {
					...funcRule(),
					...funcRule('fullName'),
					...funcRule('contacts'),
					...funcRule('ip'),
					...funcRule('client'),
					...funcRule('status'),
					...funcRule('user'),
				};
			case ROLES.ADMIN:
				return {
					...funcRule(),
					...funcRule('fullName'),
					...funcRule('actions'),
					...funcRule('id'),
					...funcRule('contacts'),
					...funcRule('ip'),
					...funcRule('client'),
					...funcRule('status'),
					...funcRule('user'),
				};
			case ROLES.SUPPORT:
				return {
					...funcRule(),
					...funcRule('fullName'),
					...funcRule('id'),
					...funcRule('client'),
					...funcRule('status'),
					...funcRule('user'),
					...funcRule('contacts'),
					...funcRule('actions'),
				};
			case ROLES.ACCOUNTANT:
				return {
					...funcRule('id'),
					...funcRule('fullName'),
					...funcRule('ip'),
					...funcRule('status'),
					...funcRule('clientStatus'),
					...funcRule('user'),
					...funcRule('country'),
					...funcRule('geolocation'),
					...funcRule('preLanding'),
					...funcRule('landing'),
					...funcRule('sentAt'),
				};
			default:
				return {
					...funcRule(),
					...funcRule('id'),
					...funcRule('fullName'),
					...funcRule('client'),
					...funcRule('status'),
					...funcRule('user'),
				};
		}
	}
}
