export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
// Local
export const LOCAL_DASHBOARD = '/';

const AUTH = '/auth';
const PASSWORD = `${AUTH}/password`;
export const LOCAL_LOGIN = `${AUTH}/login`;
export const LOCAL_PASSWORD_FORGOT = `${PASSWORD}/forgot`;
export const LOCAL_PASSWORD_RESET = `${PASSWORD}/reset/:token`;

const USERS = '/users';
export const LOCAL_USERS_LIST = USERS;
export const LOCAL_USER_CREATE = `${USERS}/create`;
export const LOCAL_USER_EDIT = `${USERS}/:userId`;
export const LOCAL_SET_USER_EDIT = userId => `${USERS}/${userId}`;

const CARDS = '/cards';
export const LOCAL_CARDS_LIST = CARDS;
export const LOCAL_CARDS_CREATE = `${CARDS}/create`;
export const LOCAL_CARDS_EDIT = `${CARDS}/:cardId`;
export const LOCAL_SET_CARDS_EDIT = cardId => `${CARDS}/${cardId}`;

const LEADS = '/leads';
export const LOCAL_LEADS_LIST = LEADS;
export const LOCAL_LEAD_EDIT = `${LEADS}/:leadId`;
export const LOCAL_LEAD_ARCHIVE = `${LEADS}/archive`;
export const LOCAL_LEAD_NOT_VALID = `${LEADS}/not_valid`;
export const LOCAL_SET_LEAD_EDIT = leadId => `${LEADS}/${leadId}`;

const DOUBLES = '/doubles';
export const LOCAL_DOUBLE_LIST = DOUBLES;

const AUTO_RESEND = '/auto-resend';
export const LOCAL_AUTO_RESEND_LIST = AUTO_RESEND;

const DOMAINS = '/domains';
export const LOCAL_DOMAINS_LIST = DOMAINS;
export const LOCAL_DOMAIN_CREATE = `${DOMAINS}/create`;
export const LOCAL_DOMAIN_EDIT = `${DOMAINS}/:domainId`;
export const LOCAL_SET_DOMAIN_EDIT = domainId => `${DOMAINS}/${domainId}`;

const SUB_DOMAIN = '/sub-domains';
export const LOCAL_SUB_DOMAINS_LIST = SUB_DOMAIN;
export const LOCAL_SUB_DOMAIN_CREATE = `${SUB_DOMAIN}/create`;
export const LOCAL_SUB_DOMAIN_EDIT = `${SUB_DOMAIN}/:subDomainId`;
export const LOCAL_SET_SUB_DOMAIN_EDIT = domainId =>
	`${SUB_DOMAIN}/${domainId}`;

const CURRENCY = '/currencies';
export const LOCAL_CURRENCY_LIST = CURRENCY;
export const LOCAL_CURRENCY_CREATE = `${CURRENCY}/create`;
export const LOCAL_CURRENCY_EDIT = `${CURRENCY}/:currencyId`;
export const LOCAL_SET_CURRENCY_EDIT = currencyId =>
	`${CURRENCY}/${currencyId}`;

const REDIRECT_DOMAINS = '/redirect-domains';
export const LOCAL_REDIRECT_DOMAINS_LIST = REDIRECT_DOMAINS;
export const LOCAL_REDIRECT_DOMAINS_CREATE = `${REDIRECT_DOMAINS}/create`;
export const LOCAL_REDIRECT_DOMAINS_EDIT = `${REDIRECT_DOMAINS}/:redirectDomainId`;
export const LOCAL_SET_REDIRECT_DOMAINS_EDIT = redirectDomainId =>
	`${REDIRECT_DOMAINS}/${redirectDomainId}`;

const REDIRECT_SUB_DOMAINS = '/redirect-sub-domains';
export const LOCAL_REDIRECT_SUB_DOMAINS_LIST = REDIRECT_SUB_DOMAINS;
export const LOCAL_REDIRECT_SUB_DOMAINS_CREATE = `${REDIRECT_SUB_DOMAINS}/create`;
export const LOCAL_REDIRECT_SUB_DOMAINS_EDIT = `${REDIRECT_SUB_DOMAINS}/:redirectSubDomainId`;
export const LOCAL_SET_REDIRECT_SUB_DOMAINS_EDIT = redirectSubDomainId =>
	`${REDIRECT_SUB_DOMAINS}/${redirectSubDomainId}`;

const APPS = '/apps';
export const LOCAL_APPS_LIST = APPS;
export const LOCAL_APPS_CREATE = `${APPS}/create`;
export const LOCAL_APPS_EDIT = `${APPS}/:siteId`;
export const LOCAL_SET_APPS_EDIT = appId => `${APPS}/${appId}`;

const BINOMS = '/binoms';
export const LOCAL_BINOMS_LIST = BINOMS;
export const LOCAL_BINOMS_CREATE = `${BINOMS}/create`;
export const LOCAL_BINOMS_EDIT = `${BINOMS}/:binomId`;
export const LOCAL_SET_BINOMS_EDIT = binomId => `${BINOMS}/${binomId}`;

const STATUSES = '/statuses';
export const LOCAL_STATUSES_LIST = STATUSES;
export const LOCAL_STATUSES_NEW_STATUS = `${STATUSES}/new-statuses`;
export const LOCAL_STATUSES_CREATE = `${STATUSES}/create`;
export const LOCAL_STATUSES_EDIT = `${STATUSES}/:statusId`;
export const LOCAL_SET_STATUSES_EDIT = statusId => `${STATUSES}/${statusId}`;

const TOTAL_CAP = '/cap';
export const LOCAL_TOTAL_CAP_LIST = TOTAL_CAP;
export const LOCAL_TOTAL_CAP_CREATE = `${TOTAL_CAP}/create`;
export const LOCAL_TOTAL_CAP_EDIT = `${TOTAL_CAP}/:totalCapId`;
export const LOCAL_SET_TOTAL_CAP_EDIT = totalCapId =>
	`${TOTAL_CAP}/${totalCapId}`;

const SUB_GROUP_CAP = '/sub-groups-cap';
export const LOCAL_SUB_GROUP_CAP_LIST = SUB_GROUP_CAP;
export const LOCAL_SUB_GROUP_CAP_CREATE = `${SUB_GROUP_CAP}/create`;
export const LOCAL_SUB_GROUP_CAP_EDIT = `${SUB_GROUP_CAP}/:subGroupCapId`;
export const LOCAL_SET_SUB_GROUP_CAP_EDIT = subGroupCapId =>
	`${SUB_GROUP_CAP}/${subGroupCapId}`;

const GROUP_CAP = '/groups-cap';
export const LOCAL_GROUP_CAP_LIST = GROUP_CAP;
export const LOCAL_GROUP_CAP_CREATE = `${GROUP_CAP}/create`;
export const LOCAL_GROUP_CAP_EDIT = `${GROUP_CAP}/:groupCapId`;
export const LOCAL_SET_GROUP_CAP_EDIT = groupCapId =>
	`${GROUP_CAP}/${groupCapId}`;

const COUNTRIES_LIMIT = '/countries-cap';
export const LOCAL_COUNTRIES_LIMIT_LIST = COUNTRIES_LIMIT;
export const LOCAL_COUNTRIES_LIMIT_CREATE = `${COUNTRIES_LIMIT}/create`;
export const LOCAL_COUNTRIES_LIMIT_EDIT = `${COUNTRIES_LIMIT}/:countryLimitId`;
export const LOCAL_SET_COUNTRIES_LIMIT_EDIT = countryLimitId =>
	`${COUNTRIES_LIMIT}/${countryLimitId}`;

const GEO = '/geo';
export const LOCAL_GEO_LIST = GEO;
export const LOCAL_GEO_CREATE = `${GEO}/create`;
export const LOCAL_GEO_CAP = `${GEO}/cap`;
export const LOCAL_GEO_EDIT = `${GEO}/:geoId`;
export const LOCAL_SET_GEO_EDIT = geoId => `${GEO}/${geoId}`;

const GEO_LIMIT = '/geolocation-limits';
export const LOCAL_GEO_LIMIT_LIST = GEO_LIMIT;
export const LOCAL_GEO_LIMIT_CREATE = `${GEO_LIMIT}/create`;
export const LOCAL_GEO_LIMIT_EDIT = `${GEO_LIMIT}/:geoLimitId`;
export const LOCAL_SET_GEO_LIMIT_EDIT = geoLimitId =>
	`${GEO_LIMIT}/${geoLimitId}`;

const GEO_GROUP = '/geo-groups';

const FORM = '/forms';
export const LOCAL_FORM_LIST = FORM;
export const LOCAL_FORM_CREATE = `${FORM}/create`;
export const LOCAL_FORM_EDIT = `${FORM}/:formId`;
export const LOCAL_SET_FORM_EDIT = formId => `${FORM}/${formId}`;

const CLIENTS = '/clients';
export const LOCAL_CLIENTS_LIST = CLIENTS;
export const LOCAL_CLIENTS_EDIT = `${CLIENTS}/:clientId`;
export const LOCAL_SET_CLIENTS_EDIT = clientId => `${CLIENTS}/${clientId}`;

const SNAPSHOTS = '/snapshots';
export const LOCAL_SNAPSHOTS_LIST = SNAPSHOTS;
export const LOCAL_SNAPSHOTS_GET = `${SNAPSHOTS}/:snapshotId`;
export const LOCAL_SET_SNAPSHOTS_GET = snapshotId =>
	`${SNAPSHOTS}/${snapshotId}`;

const WHITE_PAGE = '/white-pages';
export const LOCAL_WHITE_PAGE_LIST = WHITE_PAGE;
export const LOCAL_WHITE_PAGE_CREATE = `${WHITE_PAGE}/create`;
export const LOCAL_WHITE_PAGE_EDIT = `${WHITE_PAGE}/:itemId`;
export const LOCAL_SET_WHITE_PAGE_EDIT = whitePageId =>
	`${WHITE_PAGE}/${whitePageId}`;

const LANDINGS = '/landings';
export const LOCAL_LANDINGS_LIST = LANDINGS;
export const LOCAL_LANDINGS_CREATE = `${LANDINGS}/create`;
export const LOCAL_LANDINGS_EDIT = `${LANDINGS}/:landingId`;
export const LOCAL_SET_LANDINGS_EDIT = landingId => `${LANDINGS}/${landingId}`;

const PRE_LANDINGS = '/pre-landings';
export const LOCAL_PRE_LANDINGS_LIST = PRE_LANDINGS;
export const LOCAL_PRE_LANDINGS_CREATE = `${PRE_LANDINGS}/create`;
export const LOCAL_PRE_LANDINGS_EDIT = `${PRE_LANDINGS}/:preLandingId`;
export const LOCAL_SET_PRE_LANDINGS_EDIT = id => `${PRE_LANDINGS}/${id}`;

const DEMONETIZATIONS = '/demonetizations';
export const LOCAL_DEMONETIZATIONS_LIST = DEMONETIZATIONS;
export const LOCAL_DEMONETIZATIONS_CREATE = `${DEMONETIZATIONS}/create`;
export const LOCAL_DEMONETIZATIONS_EDIT = `${DEMONETIZATIONS}/:demonetizationId`;
export const LOCAL_SET_DEMONETIZATIONS_EDIT = id => `${DEMONETIZATIONS}/${id}`;

const POST_LANDINGS = '/post-landings';
export const LOCAL_POST_LANDINGS_LIST = POST_LANDINGS;
export const LOCAL_POST_LANDINGS_CREATE = `${POST_LANDINGS}/create`;
export const LOCAL_POST_LANDINGS_EDIT = `${POST_LANDINGS}/:postLandingId`;
export const LOCAL_SET_POST_LANDINGS_EDIT = id => `${POST_LANDINGS}/${id}`;

const BLACKLIST_CONTENT_URLS = '/blacklist-content-urls';
export const LOCAL_BLACKLIST_CONTENT_URLS_LIST = BLACKLIST_CONTENT_URLS;
export const LOCAL_BLACKLIST_CONTENT_URLS_CREATE = `${BLACKLIST_CONTENT_URLS}/create`;
export const LOCAL_BLACKLIST_CONTENT_URLS_EDIT = `${BLACKLIST_CONTENT_URLS}/:blacklistContentUrl`;
export const LOCAL_SET_BLACKLIST_CONTENT_URLS_EDIT = id =>
	`${BLACKLIST_CONTENT_URLS}/${id}`;

const COUNTRIES_BLACKLIST = '/countries-blacklist';
export const LOCAL_COUNTRIES_BLACKLIST_LIST = COUNTRIES_BLACKLIST;
export const LOCAL_COUNTRIES_BLACKLIST_CREATE = `${COUNTRIES_BLACKLIST}/create`;
export const LOCAL_COUNTRIES_BLACKLIST_EDIT = `${COUNTRIES_BLACKLIST}/:countryBlacklistId`;
export const LOCAL_SET_COUNTRIES_BLACKLIST_EDIT = id =>
	`${COUNTRIES_BLACKLIST}/${id}`;

const BILLING = '/billings';
export const LOCAL_BILLING = BILLING;
export const LOCAL_BILLING_CREATE = `${BILLING}/create`;
export const LOCAL_BILLING_UPLOAD= `${BILLING}/upload`;
export const LOCAL_BILLING_UPDATE = `${BILLING}/:billingId`;
export const LOCAL_SET_BILLING_EDIT = id => `${BILLING}/${id}`;

const OFFICES_EXPENSES = '/office-expenses';
export const LOCAL_OFFICES_EXPENSES = OFFICES_EXPENSES;
export const LOCAL_OFFICES_EXPENSES_CREATE = `${OFFICES_EXPENSES}/create`;
export const LOCAL_OFFICES_EXPENSES_UPDATE = `${OFFICES_EXPENSES}/:officesExpensesId`;
export const LOCAL_SET_OFFICES_EXPENSES_EDIT = id =>
	`${OFFICES_EXPENSES}/${id}`;

const BLACKLIST_IP = '/ip-blacklist';
export const LOCAL_BLACKLIST_IP = BLACKLIST_IP;
export const LOCAL_BLACKLIST_IP_CREATE = `${BLACKLIST_IP}/create`;
export const LOCAL_BLACKLIST_IP_EDIT = `${BLACKLIST_IP}/:blacklistIP`;
export const LOCAL_SET_BLACKLIST_IP_EDIT = id => `${BLACKLIST_IP}/${id}`;

const GROUPS = '/groups';
export const LOCAL_GROUPS = GROUPS;
export const LOCAL_GROUPS_CREATE = `${GROUPS}/create`;
export const LOCAL_GROUPS_EDIT = `${GROUPS}/:groupId`;
export const LOCAL_SET_GROUPS_EDIT = id => `${GROUPS}/${id}`;

const SUB_GROUPS = '/sub-groups';
export const LOCAL_SUB_GROUPS_CREATE = `${SUB_GROUPS}/create`;
export const LOCAL_SUB_GROUPS_EDIT = `${SUB_GROUPS}/:subGroupId`;
export const LOCAL_SET_SUB_GROUPS_EDIT = id => `${SUB_GROUPS}/${id}`;

const DEPOSITS = '/deposits';
const DEPOSITS_GEO = `${DEPOSITS}/geolocations`;
const DEPOSITS_USERS = `${DEPOSITS}/users`;
const DEPOSITS_CONSTRUCTOR = `${DEPOSITS}/constructor`;
const DEPOSITS_GEOLOCATION = `${DEPOSITS}/deposits-geo`;
const DEPOSITS_CALCULATIONS_SALARY = `${DEPOSITS}/calculations-salary`;
const DEPOSITS_LINKS = `${DEPOSITS}/links`;
const DEPOSITS_CLIENTS_GEOLOCATIONS = `${DEPOSITS}/clients/geo`;
const DEPOSITS_CLIENTS_COUNTRIES = `${DEPOSITS}/clients/countries`;
const DEPOSITS_CLIENTS_UNIFIED_STATUS = `${DEPOSITS}/clients/unified-status`;
const DEPOSITS_LANDINGS = `${DEPOSITS}/landings`;
const DEPOSITS_TEAM_LEADS = `${DEPOSITS}/team-leads`;
const DEPOSITS_PRE_LANDINGS = `${DEPOSITS}/pre-landings`;
const DEPOSITS_COUNTRIES = `${DEPOSITS}/countries`;
const STATISTICS_CLIENT_HISTORY = `${CLIENTS}/history`;

const BALANCE = '/balance';

const STATISTICS = '/statistics';
export const LOCAL_STATISTICS = STATISTICS;
export const LOCAL_STATISTICS_GENERAL = `${STATISTICS}/general`;
export const LOCAL_STATISTICS_CPL = `${STATISTICS}/cpl`;
export const LOCAL_STATISTICS_CALCULATIONS_SALARY = `${STATISTICS}/calculations-salary`;
export const LOCAL_OFFICES_EXPENSES_TECH_SPEND = `${STATISTICS}${OFFICES_EXPENSES}/tech-spend`;
export const LOCAL_STATISTICS_GROUPS = `${STATISTICS}${GROUPS}`;
export const LOCAL_STATISTICS_DEPOSITS_GEOLOCATIONS = `${STATISTICS}${DEPOSITS_GEO}`;
export const LOCAL_STATISTICS_DEPOSITS_GEO = `${STATISTICS}${DEPOSITS_GEOLOCATION}`;
export const LOCAL_STATISTICS_PAYMENT_TYPE_CPL = `${STATISTICS}/payment-type-cpl`;
export const LOCAL_STATISTICS_DEPOSITS_USERS = `${STATISTICS}${DEPOSITS_USERS}`;
export const LOCAL_STATISTICS_DEPOSITS_LINKS = `${STATISTICS}${DEPOSITS_LINKS}`;
export const LOCAL_STATISTICS_DEPOSITS_CONSTRUCTOR = `${STATISTICS}${DEPOSITS_CONSTRUCTOR}`;
export const LOCAL_STATISTICS_DEPOSITS_CLIENTS_COUNTRIES = `${STATISTICS}${DEPOSITS_CLIENTS_COUNTRIES}`;
export const LOCAL_STATISTICS_DEPOSITS_CLIENTS_GEOLOCATIONS = `${STATISTICS}${DEPOSITS_CLIENTS_GEOLOCATIONS}`;
export const LOCAL_STATISTICS_DEPOSITS_CLIENTS_UNIFIED_STATUS = `${STATISTICS}${DEPOSITS_CLIENTS_UNIFIED_STATUS}`;
export const LOCAL_STATISTICS_DEPOSITS_LANDINGS = `${STATISTICS}${DEPOSITS_LANDINGS}`;
export const LOCAL_STATISTICS_DEPOSITS_TEAM_LEADS = `${STATISTICS}${DEPOSITS_TEAM_LEADS}`;
export const LOCAL_STATISTICS_DEPOSITS_PRE_LANDINGS = `${STATISTICS}${DEPOSITS_PRE_LANDINGS}`;
export const LOCAL_STATISTICS_DEPOSITS_COUNTRIES = `${STATISTICS}${DEPOSITS_COUNTRIES}`;
export const LOCAL_STATISTICS_CLIENT_HISTORY = `${STATISTICS}${STATISTICS_CLIENT_HISTORY}`;
export const LOCAL_STATISTICS_BALANCE = `${STATISTICS}${BALANCE}`;
export const LOCAL_STATISTICS_BALANCE_EDIT = `${STATISTICS}${BALANCE}/:clientId`;
export const LOCAL_SET_STATISTICS_BALANCE_EDIT = clientId =>
	`${LOCAL_STATISTICS_BALANCE}/${clientId}`;

const CPL_RULE = '/cpl-rules';
export const LOCAL_CPL_RULE_LIST = CPL_RULE;

// API
export const API_LOGIN = `${AUTH}/login`;
export const API_PASSWORD_FORGOT = `${PASSWORD}/forgot`;
export const API_PASSWORD_RESET = `${PASSWORD}/reset`;
export const API_TOKEN_REFRESH = `${AUTH}/token/refresh`;

const API_LEADS = LEADS;
export const API_GET_LEADS = API_LEADS;
export const API_IMPORT_LEADS = `${API_LEADS}/import`;
export const API_LEAD_FB_CONVERSION = `${API_LEADS}/fb-conversion`;
export const API_GET_LEADS_COUNT = `${API_LEADS}/count`;
export const API_GET_USERS_LEADS_COUNT = `${API_LEADS}/user/count`;
export const API_LEAD_STATUS = id => `${API_LEADS}/${id}/status`;
export const API_LEAD_UPDATE = id => `${API_LEADS}/${id}/update`;
export const API_LEAD_GET = id => `${API_LEADS}/${id}/get`;
export const API_LEAD_ARCHIVE = id => `${API_LEADS}/${id}/archive`;
export const API_LEAD_RESTORE = id => `${API_LEADS}/${id}/restore`;
export const API_LEAD_RESEND = id => `${API_LEADS}/${id}/resend`;
export const API_LEAD_DELETE = id => `${API_LEADS}/${id}/delete`;
export const API_LEAD_HISTORY = id => `${API_LEADS}/${id}/history`;
export const API_LEAD_RESPONSE_REQUEST_HISTORY = id =>
	`${API_LEADS}/${id}/response-request-history`;
export const API_LEAD_RESEND_WITH_IP = id =>
	`${API_LEADS}/${id}/resend-with-ip`;

export const API_DOMAINS_LIST = DOMAINS;
export const API_DOMAIN_CREATE = DOMAINS;
export const API_DOMAIN_GET = domainId => `${DOMAINS}/${domainId}`;
export const API_DOMAIN_UPDATE = domainId => `${DOMAINS}/${domainId}`;
export const API_DOMAIN_DELETE = domainId => `${DOMAINS}/${domainId}/delete`;
export const API_DOMAIN_RESTORE = domainId => `${DOMAINS}/${domainId}/restore`;

export const API_SUB_DOMAINS_LIST = SUB_DOMAIN;
export const API_SUB_DOMAIN_CREATE = SUB_DOMAIN;
export const API_SUB_DOMAIN_GET = domainId => `${SUB_DOMAIN}/${domainId}`;
export const API_SUB_DOMAIN_UPDATE = domainId => `${SUB_DOMAIN}/${domainId}`;
export const API_SUB_DOMAIN_DELETE = domainId =>
	`${SUB_DOMAIN}/${domainId}/delete`;
export const API_SUB_DOMAIN_RESTORE = domainId =>
	`${SUB_DOMAIN}/${domainId}/restore`;

export const API_APPS_LIST = APPS;
export const API_APPS_CREATE = APPS;
export const API_APPS_GET = appId => `${APPS}/${appId}`;
export const API_APPS_UPDATE = appId => `${APPS}/${appId}`;
export const API_APPS_DELETE = appId => `${APPS}/${appId}/delete`;

export const API_BINOMS_LIST = BINOMS;
export const API_BINOMS_CREATE = BINOMS;
export const API_BINOMS_GET = binomId => `${BINOMS}/${binomId}`;
export const API_BINOMS_UPDATE = binomId => `${BINOMS}/${binomId}`;
export const API_BINOMS_DELETE = binomId => `${BINOMS}/${binomId}/delete`;

export const API_TOTAL_CAP_LIST = TOTAL_CAP;
export const API_TOTAL_CAP_CREATE = TOTAL_CAP;
export const API_TOTAL_CAP_GET = totalCapId => `${TOTAL_CAP}/${totalCapId}`;
export const API_TOTAL_CAP_UPDATE = totalCapId => `${TOTAL_CAP}/${totalCapId}`;
export const API_TOTAL_CAP_RENEW = totalCapId =>
	`${TOTAL_CAP}/${totalCapId}/renew`;
export const API_TOTAL_CAP_DELETE = totalCapId =>
	`${TOTAL_CAP}/${totalCapId}/delete`;

export const API_SUB_GROUP_CAP_LIST = SUB_GROUP_CAP;
export const API_SUB_GROUP_CAP_CREATE = SUB_GROUP_CAP;
export const API_SUB_GROUP_CAP_GET = subGroupCapId =>
	`${SUB_GROUP_CAP}/${subGroupCapId}`;
export const API_SUB_GROUP_CAP_UPDATE = subGroupCapId =>
	`${SUB_GROUP_CAP}/${subGroupCapId}`;
export const API_SUB_GROUP_CAP_RENEW = subGroupCapId =>
	`${SUB_GROUP_CAP}/${subGroupCapId}/renew`;
export const API_SUB_GROUP_CAP_DELETE = subGroupCapId =>
	`${SUB_GROUP_CAP}/${subGroupCapId}/delete`;

export const API_GROUP_CAP_LIST = GROUP_CAP;
export const API_GROUP_CAP_CREATE = GROUP_CAP;
export const API_GROUP_CAP_GET = groupCapId => `${GROUP_CAP}/${groupCapId}`;
export const API_GROUP_CAP_UPDATE = groupCapId => `${GROUP_CAP}/${groupCapId}`;
export const API_GROUP_CAP_RENEW = groupCapId =>
	`${GROUP_CAP}/${groupCapId}/renew`;
export const API_GROUP_CAP_DELETE = groupCapId =>
	`${GROUP_CAP}/${groupCapId}/delete`;

export const API_COUNTRIES_LIMIT_LIST = COUNTRIES_LIMIT;
export const API_COUNTRIES_LIMIT_CREATE = COUNTRIES_LIMIT;
export const API_COUNTRIES_LIMIT_GET = countriesLimitId =>
	`${COUNTRIES_LIMIT}/${countriesLimitId}`;
export const API_COUNTRIES_LIMIT_UPDATE = countriesLimitId =>
	`${COUNTRIES_LIMIT}/${countriesLimitId}`;
export const API_COUNTRIES_LIMIT_DELETE = countriesLimitId =>
	`${COUNTRIES_LIMIT}/${countriesLimitId}/delete`;

export const API_STATUSES_LIST = STATUSES;
export const API_STATUSES_CREATE = STATUSES;
export const API_STATUSES_ALIASES_WITHOUT_STATUS = `${STATUSES}/new-client-aliases`;
export const API_STATUSES_GET = statusId => `${STATUSES}/${statusId}`;
export const API_STATUSES_UPDATE = statusId => `${STATUSES}/${statusId}`;
export const API_STATUSES_DELETE = statusId => `${STATUSES}/${statusId}/delete`;

export const API_CLIENTS_LIST = CLIENTS;
export const API_CLIENTS_BALANCE = `${CLIENTS}${BALANCE}`;
export const API_CLIENT_GET = clientId => `${CLIENTS}/${clientId}`;
export const API_CLIENT_UPDATE = clientId => `${CLIENTS}/${clientId}`;
export const API_CLIENT_CLONE = clientId => `${CLIENTS}/${clientId}/copy`;
export const API_CLIENTS_UPDATE_GROUP_INDEX = clientId =>
	`${CLIENTS}/${clientId}/group-index`;
export const API_CLIENTS_ERROR = clientId =>
	`${CLIENTS}/${clientId}/last-error`;
export const API_CLIENT_UPDATE_STATUS = clientId =>
	`${CLIENTS}/${clientId}/status`;
export const API_CLIENT_UPDATE_RESENT_STATUS = clientId =>
	`${CLIENTS}/${clientId}/resent-status`;
export const API_CLIENT_SEND_TEST = clientId => `${CLIENTS}/${clientId}/test`;
export const API_CLIENT_SEND_RANDOM_TEST = clientId =>
	`${CLIENTS}/${clientId}/random-test`;
export const API_CLIENT_LEAD_HISTORY = clientId =>
	`${CLIENTS}/${clientId}/history`;
export const API_CLIENT_UPDATE_TIME_FRAMES = clientId =>
	`${CLIENTS}/${clientId}/time-frames`;
export const API_TIME_FRAME_GEO_CAP = `${CLIENTS}/timeframe-cap`;
export const API_CLIENT_RESPONSE_REQUEST_HISTORY = id =>
	`${CLIENTS}/${id}/response-request-history`;

export const API_LANDINGS_LIST = LANDINGS;
export const API_LANDINGS_GEOLOCATION_LIST = `${LANDINGS}/geolocation`;
export const API_LANDINGS_GET = landingId => `${LANDINGS}/${landingId}`;
export const API_LANDINGS_UPDATE = landingId => `${LANDINGS}/${landingId}`;
export const API_LANDINGS_DELETE = landingId =>
	`${LANDINGS}/${landingId}/delete`;
export const API_LANDINGS_RESTORE = landingId =>
	`${LANDINGS}/${landingId}/restore`;
export const API_LANDINGS_CREATE = LANDINGS;

export const API_COUNTRY_LIST = '/countries';

export const API_SNAPSHOTS_LIST = SNAPSHOTS;
export const API_SNAPSHOT_GET = snapshotId => `${SNAPSHOTS}/${snapshotId}`;

export const API_USERS_LIST = USERS;
export const API_USER_CREATE = USERS;
export const API_USER_UPDATE = userId => `${USERS}/${userId}`;
export const API_USER_GET = userId => `${USERS}/${userId}`;
export const API_USER_DELETE = userId => `${USERS}/${userId}/delete`;
export const API_USER_UPDATE_STATUS = userId => `${USERS}/${userId}/status`;

export const API_CARDS_LIST = CARDS;
export const API_CARD_CREATE = CARDS;
export const API_CARD_UPDATE = cardId => `${CARDS}/${cardId}`;
export const API_CARD_GET = cardId => `${CARDS}/${cardId}`;
export const API_CARD_DELETE = cardId => `${CARDS}/${cardId}/delete`;
export const API_CARD_PRIMARY = cardId => `${CARDS}/${cardId}/primary`;

export const API_WHITE_PAGE_LIST = WHITE_PAGE;
export const API_WHITE_PAGE_CREATE = WHITE_PAGE;
export const API_WHITE_PAGE_GET = whitePageId => `${WHITE_PAGE}/${whitePageId}`;
export const API_WHITE_PAGE_UPDATE = whitePageId =>
	`${WHITE_PAGE}/${whitePageId}`;
export const API_WHITE_PAGE_DELETE = whitePageId =>
	`${WHITE_PAGE}/${whitePageId}/delete`;

export const API_ROLES_LIST = '/roles';

export const API_GEO_LIST = GEO;
export const API_GEO_CREATE = GEO;
export const API_GEO_CAP = `${GEO}/cap`;
export const API_GEO_GET = geoId => `${GEO}/${geoId}`;
export const API_GEO_UPDATE = geoId => `${GEO}/${geoId}`;
export const API_GEO_DELETE = geoId => `${GEO}/${geoId}/delete`;

export const API_GEO_GROUP_LIST = GEO_GROUP;
export const API_GEO_GROUP_CREATE = GEO_GROUP;
export const API_GEO_GROUP_GET = geoGroupId => `${GEO_GROUP}/${geoGroupId}`;
export const API_GEO_GROUP_UPDATE = geoGroupId => `${GEO_GROUP}/${geoGroupId}`;
export const API_GEO_GROUP_DELETE = geoGroupId =>
	`${GEO_GROUP}/${geoGroupId}/delete`;

const INPUT = '/inputs';
export const API_INPUT_LIST = INPUT;
export const API_INPUT_GET = inputId => `${INPUT}/${inputId}`;

export const API_FORM_LIST = FORM;
export const API_FORM_CREATE = FORM;
export const API_FORM_GET = formId => `${FORM}/${formId}`;
export const API_FORM_UPDATE = formId => `${FORM}/${formId}`;
export const API_FORM_DELETE = formId => `${FORM}/${formId}/delete`;

export const CPL = '/cpl';
export const API_CPL_GET = id => `${CPL}/${id}`;
const DEPOSITS_PAYMENT_TYPE_CPL = `${CPL}/payment-type-cpl`;
export const API_CPL_LIST = CPL;

export const LOGS = '/logs';
export const API_LOGS_LIST = LOGS;
export const LOCAL_LOGS_LIST = LOGS;

export const API_BILLINGS_GET = BILLING;
export const API_BILLING_POST = BILLING;
export const API_BILLING_DELETE = id => `${BILLING}/${id}/delete`;
export const API_BILLING_GET = id => `${BILLING}/${id}`;
export const API_BILLING_UPDATE = id => `${BILLING}/${id}/update`;
export const API_BILLING_UPDATE_STATUS = (id, status) =>
	`${BILLING}/${id}/status/${status}`;
export const API_BILLING_UPLOAD_CSV = `${BILLING}/upload-csv`;

export const API_OFFICES_EXPENSES_GET = OFFICES_EXPENSES;
export const API_OFFICE_EXPENSES_POST = OFFICES_EXPENSES;
export const API_OFFICE_EXPENSES_DELETE = id =>
	`${OFFICES_EXPENSES}/${id}/delete`;
export const API_OFFICE_EXPENSES_GET = id => `${OFFICES_EXPENSES}/${id}`;
export const API_OFFICE_EXPENSES_UPDATE = id =>
	`${OFFICES_EXPENSES}/${id}/update`;
export const API_OFFICE_EXPENSES_UPDATE_STATUS = (id, status) =>
	`${OFFICES_EXPENSES}/${id}/status/${status}`;
export const API_OFFICES_EXPENSES_TECH_SPEND = `${OFFICES_EXPENSES}/tech-spend`;

export const API_PRE_LANDINGS_LIST = PRE_LANDINGS;
export const API_PRE_LANDINGS_GET = id => `${PRE_LANDINGS}/${id}`;
export const API_PRE_LANDINGS_UPDATE = id => `${PRE_LANDINGS}/${id}`;
export const API_PRE_LANDINGS_DELETE = id => `${PRE_LANDINGS}/${id}/delete`;
export const API_PRE_LANDINGS_CREATE = PRE_LANDINGS;

export const API_POST_LANDINGS_LIST = POST_LANDINGS;
export const API_POST_LANDINGS_GET = id => `${POST_LANDINGS}/${id}`;
export const API_POST_LANDINGS_UPDATE = id => `${POST_LANDINGS}/${id}`;
export const API_POST_LANDINGS_DELETE = id => `${POST_LANDINGS}/${id}/delete`;
export const API_POST_LANDINGS_CREATE = POST_LANDINGS;

export const API_COUNTRIES_BLACKLIST_LIST = COUNTRIES_BLACKLIST;
export const API_COUNTRIES_BLACKLIST_GET = id => `${COUNTRIES_BLACKLIST}/${id}`;
export const API_COUNTRIES_BLACKLIST_UPDATE = id =>
	`${COUNTRIES_BLACKLIST}/${id}`;
export const API_COUNTRIES_BLACKLIST_DELETE = id =>
	`${COUNTRIES_BLACKLIST}/${id}/delete`;
export const API_COUNTRIES_BLACKLIST_CREATE = COUNTRIES_BLACKLIST;

export const API_BLACKLIST_CONTENT_URLS_LIST = BLACKLIST_CONTENT_URLS;
export const API_BLACKLIST_CONTENT_URLS_GET = id =>
	`${BLACKLIST_CONTENT_URLS}/${id}`;
export const API_BLACKLIST_CONTENT_URLS_UPDATE = id =>
	`${BLACKLIST_CONTENT_URLS}/${id}`;
export const API_BLACKLIST_CONTENT_URLS_DELETE = id =>
	`${BLACKLIST_CONTENT_URLS}/${id}/delete`;
export const API_BLACKLIST_CONTENT_URLS_CREATE = BLACKLIST_CONTENT_URLS;

export const API_DEMONETIZATIONS_LIST = DEMONETIZATIONS;
export const API_DEMONETIZATIONS_GET = id => `${DEMONETIZATIONS}/${id}`;
export const API_DEMONETIZATIONS_UPDATE = id => `${DEMONETIZATIONS}/${id}`;
export const API_DEMONETIZATIONS_DELETE = id =>
	`${DEMONETIZATIONS}/${id}/delete`;
export const API_DEMONETIZATIONS_CREATE = DEMONETIZATIONS;

export const API_REDIRECT_DOMAINS_LIST = REDIRECT_DOMAINS;
export const API_REDIRECT_DOMAINS_GET = id => `${REDIRECT_DOMAINS}/${id}`;
export const API_REDIRECT_DOMAINS_UPDATE = id => `${REDIRECT_DOMAINS}/${id}`;
export const API_REDIRECT_DOMAINS_DELETE = id =>
	`${REDIRECT_DOMAINS}/${id}/delete`;
export const API_REDIRECT_DOMAINS_CREATE = REDIRECT_DOMAINS;

export const API_REDIRECT_SUB_DOMAINS_LIST = REDIRECT_SUB_DOMAINS;
export const API_REDIRECT_SUB_DOMAINS_GET = id =>
	`${REDIRECT_SUB_DOMAINS}/${id}`;
export const API_REDIRECT_SUB_DOMAINS_UPDATE = id =>
	`${REDIRECT_SUB_DOMAINS}/${id}`;
export const API_REDIRECT_SUB_DOMAINS_DELETE = id =>
	`${REDIRECT_SUB_DOMAINS}/${id}/delete`;
export const API_REDIRECT_SUB_DOMAINS_CREATE = REDIRECT_SUB_DOMAINS;

export const API_CURRENCY_LIST = `${BASE_API_URL}/currencies`;
export const API_CURRENCY_GET = currencyId =>
	`${BASE_API_URL}/currencies/${currencyId}`;
export const API_CURRENCY_UPDATE = currencyId =>
	`${BASE_API_URL}/currencies/${currencyId}`;
export const API_CURRENCY_DELETE = currencyId =>
	`${BASE_API_URL}/currencies/${currencyId}/delete`;
export const API_CURRENCY_RESTORE = currencyId =>
	`${BASE_API_URL}/currencies/${currencyId}/restore`;
export const API_CURRENCY_CREATE = `${BASE_API_URL}/currencies/`;

export const API_GET_DEPOSITS_GEO = DEPOSITS_COUNTRIES;
export const API_GET_DEPOSITS_USERS = DEPOSITS_USERS;
export const API_GET_DEPOSITS_LINKS = DEPOSITS_LINKS;
export const API_GET_DEPOSITS_CALCULATIONS_SALARY = DEPOSITS_CALCULATIONS_SALARY;
export const API_IMPORT_DEPOSITS_CALCULATIONS_SALARY = `${DEPOSITS_CALCULATIONS_SALARY}/import`;
export const API_GET_DEPOSITS_CONSTRUCTOR = DEPOSITS_CONSTRUCTOR;
export const API_IMPORT_DEPOSITS_CONSTRUCTOR = `${DEPOSITS_CONSTRUCTOR}/import`;
export const API_GET_DEPOSITS_GEOLOCATION = DEPOSITS_GEOLOCATION;
export const API_GET_PAYMENT_TYPE_CPL = DEPOSITS_PAYMENT_TYPE_CPL;
export const API_GET_DEPOSITS_CLIENTS_GEOLOCATIONS = DEPOSITS_CLIENTS_GEOLOCATIONS;
export const API_GET_DEPOSITS_CLIENTS_COUNTRIES = DEPOSITS_CLIENTS_COUNTRIES;
export const API_GET_DEPOSITS_CLIENTS_UNIFIED_STATUS = DEPOSITS_CLIENTS_UNIFIED_STATUS;
export const API_GET_DEPOSITS_LANDINGS = DEPOSITS_LANDINGS;
export const API_GET_DEPOSITS_PRE_LANDINGS = DEPOSITS_PRE_LANDINGS;
export const API_GET_DEPOSITS_TEAM_LEADS = DEPOSITS_TEAM_LEADS;
export const API_GET_DEPOSITS_DIRECTIONS = DEPOSITS_GEO;
export const API_GET_STATISTICS_CLIENT_HISTORY = STATISTICS_CLIENT_HISTORY;

export const API_BLACKLIST_IP = BLACKLIST_IP;
export const API_BLACKLIST_IP_CREATE = BLACKLIST_IP;
export const API_BLACKLIST_IP_GET = id => `${BLACKLIST_IP}/${id}`;
export const API_BLACKLIST_IP_UPDATE = id => `${BLACKLIST_IP}/${id}`;
export const API_BLACKLIST_IP_DELETE = id => `${BLACKLIST_IP}/${id}/delete`;

export const API_GROUPS_LIST = GROUPS;
export const API_GROUPS_CREATE = GROUPS;
export const API_GROUPS_GET = id => `${GROUPS}/${id}`;
export const API_GROUPS_UPDATE = id => `${GROUPS}/${id}`;
export const API_GROUPS_DELETE = id => `${GROUPS}/${id}/delete`;
export const API_GROUPS_STATISTICS = `${GROUPS}/stats`;
export const API_IMPORT_GROUPS_STATISTICS = `${GROUPS}/stats/import`;

export const API_SUB_GROUPS_LIST = SUB_GROUPS;
export const API_SUB_GROUPS_CREATE = SUB_GROUPS;
export const API_SUB_GROUPS_GET = id => `${SUB_GROUPS}/${id}`;
export const API_SUB_GROUPS_UPDATE = id => `${SUB_GROUPS}/${id}`;
export const API_SUB_GROUPS_DELETE = id => `${SUB_GROUPS}/${id}/delete`;
export const API_SUB_GROUPS_HISTORY = id => `${SUB_GROUPS}/${id}/history`;
export const API_SUB_GROUPS_RESPONSE_REQUEST_HISTORY = id =>
	`${SUB_GROUPS}/${id}/response-request-history`;

const API_AUTO_RESEND = '/auto-resent';
export const API_AUTO_RESEND_LIST = API_AUTO_RESEND;
export const API_CREATE_AUTO_RESEND = API_AUTO_RESEND;
export const API_DELETE_AUTO_RESEND = id => `${API_AUTO_RESEND}/${id}/delete`;

export const API_CPL_RULE_LIST = CPL_RULE;
export const API_CPL_RULE_CREATE = `${CPL_RULE}`;
export const API_CPL_RULE_STATISTIC = `${CPL_RULE}/statistic`;
export const API_CPL_RULE_UPDATE = (year, month) =>
	`${CPL_RULE}/${year}/${month}`;
export const API_CPL_RULE_DELETE = (year, month) =>
	`${CPL_RULE}/${year}/${month}/delete`;
